import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor'
import { activateTGPDataType } from '@/Pages/Frontend/Components/TGPlus/CreateSubscription/CreateSubscription'

AxiosInterceptor()

const tradegridPlusService = {
  async index(pageNumber: number, sortOrder: string, status: string) {
    return axios.get(
      `/tradegridplus?pageNumber=${pageNumber}&sortOrder=${sortOrder}&status=${status}`,
    )
  },
  async users(pageNumber: number, sortOrder: string) {
    return axios.get(`/tradegridplus/users?pageNumber=${pageNumber}&sortOrder=${sortOrder}`)
  },

  async stations(
    pageNumber: number,
    sortOrder: string,
    status: string,
    customerMsisdn: string | undefined,
  ) {
    return axios.get(
      `/tradegridplus?pageNumber=${pageNumber}&sortOrder=${sortOrder}&status=${status}&customerMsisdn=${encodeURIComponent(customerMsisdn ?? '')}`,
    )
  },

  async userReferal(pageNumber: number, userUuid: string | undefined) {
    return axios.get(`/tradegridplus/users?pageNumber=${pageNumber}&referredByUserId=${userUuid}`)
  },

  async single(pageNumber: number, id: string | undefined) {
    return axios.get(`/tradegridplus?pageNumber=${pageNumber}&id=${id}`)
  },

  async approveSubscription(status: string, id: string | undefined) {
    return axios.put(`/tradegridplus/${id}/approval/${status}`)
  },

  async updateSubscription(
    data: { solarPowerCapacity: string; monthlySubscriptionMajor: number },
    id: string | undefined,
  ) {
    return axios.put(`/tradegridplus/${id}/updateInstallationDetails`, data)
  },

  async subscriptionInstallationCompleted(
    data: { solarPowerCapacity: string | undefined; monthlySubscriptionMajor: number | undefined },
    id: string | undefined,
  ) {
    return axios.put(`/tradegridplus/${id}/installationComplete`, data)
  },
  activateTGPlus: async (data: activateTGPDataType) => {
    return await axios.post('/tradegridplus', data)
  },
}
export default tradegridPlusService
