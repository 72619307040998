import React, { FC, useState } from 'react'
import './CreateSubscription.scss'
import stateLg from './../../../../../Data/stateNG.json'
import { TGFileUpload } from '../..'
import uploadDocumentService from 'Services/uploadDocumentService'
import { useForm } from 'react-hook-form'
import tradegridPlusService from 'Services/tradegridPlusService'
import Swal from 'sweetalert2'
import { Logger } from 'Helpers/Logger'
import customerService from 'Services/customerService'
import deliveryService from 'Services/deliveryService'
import { urlencodePhoneNumber } from 'Helpers'
import { deliveryLocationType } from '../../Request/CreateRequest/CreateRequest'

export type activateTGPDataType = {
  noOfPumps: string
  dailySalesVolume: string
  stationName: string
  stationAddress: string
  stationState: string
  customerMsisdn: string
}

interface propsType {
  toggleModal: () => void
}

const CreateSubscription: FC<propsType> = ({ toggleModal }) => {
  const [request, setRequest] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [step, setStep] = useState(1)
  const [tGplusObjectId, setTGplusObjectId] = useState<string>()
  const [deliveryLocation, setDeliveryLocation] = useState<Array<deliveryLocationType>>([])

  const [files, setFiles] = useState<{ name: string; file: File | undefined; selected: boolean }[]>(
    [],
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<activateTGPDataType>()

  const submit = async (data: activateTGPDataType) => {
    console.log('data submit', data)

    setRequest(true)

    await tradegridPlusService.activateTGPlus(data).then(
      (response) => {
        console.log('response', response)
        setTGplusObjectId(response.data.data.id)
        setRequest(false)
        setStep(2)
      },
      (error: { response: { data: { error: string } } }) => {
        console.log('error', error.response)
        setRequest(false)
        Swal.fire({
          icon: 'warning',
          title: 'Oops!',
          text: error.response.data.error,
          showConfirmButton: false,
          timer: 3000,
        })
      },
    )
  }

  const submitUpload = async () => {
    setUploading(true)
    setDisableBtn(!disableBtn)
    const arraySize = files.length

    if (arraySize === 0) {
      setUploading(false)
      Swal.fire({
        icon: 'success',
        title: 'Congratulations!',
        text: 'TradeGrid Plus application request submitted successfully',
        showConfirmButton: false,
        timer: 3000,
      })

      toggleModal()

      setTimeout(() => {
        window.location.reload()
      }, 3000)

      return
    }

    for (const [index, currentDocument] of files.entries()) {
      if (currentDocument.file) {
        const formData = new FormData()
        // Update the formData object
        formData.append('file', currentDocument.file)

        await uploadDocumentService.upload(currentDocument.name, tGplusObjectId, formData).then(
          (response) => {
            console.log('FILE UPLOAD', response)

            if (index === arraySize - 1) {
              setUploading(false)
              Swal.fire({
                icon: 'success',
                title: 'Congratulations!',
                text: 'TradeGrid Plus application request submitted successfully',
                showConfirmButton: false,
                timer: 3000,
              })

              toggleModal()

              setTimeout(() => {
                window.location.reload()
              }, 3000)
            }
          },
          (error: { response: { data: { error: string } } }) => {
            console.log('FILE UPLOAD ERROR', error)
            setUploading(false)
            Swal.fire({
              icon: 'warning',
              title: 'Oops!',
              text: error.response.data.error,
              showConfirmButton: false,
              timer: 3000,
            })
          },
        )
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Oops!',
          text: 'Invalid file',
          showConfirmButton: false,
          timer: 3000,
        })
      }
    }
  }

  const [disableBtn, setDisableBtn] = useState(true)

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const file = event.target.files?.[0]
    // Check if a file with the same name already exists
    const fileIndex = files.findIndex((f) => f.name === name)

    if (fileIndex !== -1) {
      // If it exists, replace the file
      const updatedFiles = [...files]
      updatedFiles[fileIndex] = { name, file, selected: true }

      console.log(updatedFiles)
      setFiles(updatedFiles)

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Document added',
        showConfirmButton: false,
        timer: 2000,
      })
    } else {
      // If it doesn't exist, add the new file
      console.log([...files, { name, file, selected: true }])
      setFiles([...files, { name, file, selected: true }])

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Document saved',
        showConfirmButton: false,
        timer: 1000,
      })
    }
  }

  const [msisdnErrors, setMsisdnErrors] = useState<boolean>()
  const [msisdnDetails, setMsisdnDetails] = useState<{ firstName: string; lastName: string }>()

  const verifyMsisdn = async (e: { target: { value: string } }): Promise<void> => {
    const msisdn: string = e.target.value
    setMsisdnDetails(undefined)
    const endpoint = `customerMsisdn=${encodeURIComponent(msisdn)}`
    if (msisdn.length >= 10 && msisdn.length <= 15) {
      await customerService.searchCustomer(endpoint, 1, 'DESC').then(
        (response) => {
          setMsisdnDetails({
            firstName: response.data.data.dataset[0].firstName,
            lastName: response.data.data.dataset[0].lastName,
          })

          setMsisdnErrors(false)
        },
        (error: { response: { data: { error: string } } }) => {
          setMsisdnErrors(true)
          Logger(error.response)
        },
      )
    }
  }

  const getDeliveryLocations = async (msisdn: string) => {
    await deliveryService.customerDeliveryLocation(urlencodePhoneNumber(msisdn)).then(
      (response) => {
        setDeliveryLocation(response.data.data.dataset)
      },
      (error) => {
        console.log('error country', error)
      },
    )
  }

  return (
    <div className='create-subscription'>
      {step === 1 ? (
        <React.Fragment>
          <form onSubmit={handleSubmit(submit)} className='mt-5 mb-5'>
            <div className='form-group mb-3'>
              <label htmlFor='msisdn'>Customer msisdn</label>
              <input
                type='text'
                id='msisdn'
                className={errors.customerMsisdn ? 'form-control is-invalid' : 'form-control'}
                {...register('customerMsisdn', {
                  onChange: (e: { target: { value: string } }) => {
                    e.target.value.length > 10 && getDeliveryLocations(e.target.value)
                    verifyMsisdn(e)
                  },
                })}
              />
              {errors.customerMsisdn && (
                <div className='error text-danger'>{errors.customerMsisdn.message}</div>
              )}
              {msisdnErrors && <div className='error text-danger'>Phone number does not exist</div>}
              {msisdnDetails && (
                <small className='error text-success fw-bolder'>
                  {msisdnDetails.firstName} {msisdnDetails.lastName}
                </small>
              )}
            </div>

            <div className='form-input mt-3'>
              <label htmlFor='pass'>Retail station name</label>
              <input
                type='text'
                id='pass'
                {...register('stationName', { required: 'field can not be empty' })}
                className={`form-control ${errors.stationName ? 'is-invalid' : ''}`}
              />
              {errors.stationName && <span className='errors'>{errors.stationName.message}</span>}
            </div>
            <div className='form-input mt-3'>
              <label htmlFor='pass'>How many pumps are in your station</label>
              <input
                type='number'
                id='pass'
                {...register('noOfPumps', { required: 'field can not be empty' })}
                className={`form-control ${errors.noOfPumps ? 'is-invalid' : ''}`}
              />
              {errors.noOfPumps && <span className='errors'>{errors.noOfPumps.message}</span>}
            </div>
            <div className='form-input mt-3'>
              <label htmlFor='pass'>What is your daily sales volume?</label>
              <input
                type='number'
                id='pass'
                {...register('dailySalesVolume', { required: 'field can not be empty' })}
                className={`form-control ${errors.dailySalesVolume ? 'is-invalid' : ''}`}
              />
              {errors.dailySalesVolume && (
                <span className='errors'>{errors.dailySalesVolume.message}</span>
              )}
            </div>
            <div className='form-input mt-3'>
              <label htmlFor='pass'>Your station address (location and state)</label>
              <input
                type='text'
                id='pass'
                {...register('stationAddress', { required: 'field can not be empty' })}
                className={`form-control ${errors.stationAddress ? 'is-invalid' : ''}`}
              />
              {errors.stationAddress && (
                <span className='errors'>{errors.stationAddress.message}</span>
              )}
            </div>
            <div className='form-input mt-3'>
              <label htmlFor='state'>Your station state</label>
              <select
                id='state'
                {...register('stationState', { required: 'field can not be empty' })}
                className={`form-control ${errors.stationState ? 'is-invalid' : ''}`}
              >
                <option>--State--</option>
                {stateLg.map((result: { name: string }, index: React.Key) => {
                  return (
                    result.name !== 'Nationwide' && (
                      <option key={index} value={result.name}>
                        {result.name}
                      </option>
                    )
                  )
                })}
              </select>
              {errors.stationState && <span className='errors'>{errors.stationState.message}</span>}
            </div>
            <div className='form-input mt-3'>
              <button className='btn' type='submit' disabled={request}>
                Continue {request && <span className='spinner-border spinner-border-sm'></span>}
              </button>
            </div>
          </form>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className='title text-center'>Upload Documents</div>
          <div className='tagline'>Kind provide your documents for verification purpose only.</div>

          <form className='mt-5 mb-5'>
            <TGFileUpload
              files={files}
              handleFileChange={(e: React.ChangeEvent<HTMLInputElement>, name: string) =>
                handleFileChange(e, name)
              }
            ></TGFileUpload>

            <div className='form-input mt-3 terms'>
              By submitting, you agree to abide by our terms and conditions
            </div>

            <div className='form-input mt-3'>
              {/* <button className="btn" type="button" disabled={request || (files.length < 2)} onClick={submitUpload}>Submit {uploading && <span className="spinner-border spinner-border-sm"></span>}</button>  */}
              <button className='btn' type='button' disabled={request} onClick={submitUpload}>
                Submit {uploading && <span className='spinner-border spinner-border-sm'></span>}
              </button>
            </div>
          </form>
        </React.Fragment>
      )}
    </div>
  )
}

export default CreateSubscription
