import { Logger } from 'Helpers/Logger'
import { tgplusModel } from 'Models'
import tradegridPlusService from 'Services/tradegridPlusService'
import { BreadCrumb, HorizontalLoader } from 'Components'
import React, { Key, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { TimeConverter } from 'Helpers/TimeConverter'
import { CurrencyFormatter } from 'Helpers'

const TGPlusStationSubscription = () => {
  const [pageNumber, setPageNumber] = useState(1)
  const [sortOrder, setSortOrder] = useState('DESC')
  const [status, setStatus] = useState('ALL')
  const [name, setName] = useState<string>()

  const { customerMsisdn } = useParams<{ customerMsisdn: string | undefined }>()

  const [order, setOrder] = useState<tgplusModel[]>([])
  const [loading, setLoading] = useState(false)

  const [paginationData, setPaginationData] = useState<{
    pageNumber: number
    pageSize: number
    total: number
  }>({
    pageNumber: 1,
    pageSize: 20,
    total: 1,
  })

  const getTransaction = async () => {
    setLoading(true)

    await tradegridPlusService.stations(pageNumber, sortOrder, status, customerMsisdn).then(
      (response) => {
        setLoading(false)
        setOrder(response?.data.data.dataset)
        console.log('response transactionService', response)

        setPaginationData({
          pageNumber: response?.data.data.pageNumber,
          pageSize: response?.data.data.pageSize,
          total: response?.data.data.total,
        })

        const customer = response?.data.data.dataset[0].customer
        setName(customer.firstName + ' ' + customer.lastName)
      },
      (error: { response: object }) => {
        setLoading(false)
        Logger('response error', error.response)
      },
    )
  }

  useEffect(() => {
    customerMsisdn && getTransaction()
  }, [sortOrder, pageNumber, customerMsisdn])

  function formatString(input: string) {
    const lowerCaseString = input.toLowerCase()

    // Remove underscores
    const formattedString = lowerCaseString.replace(/_/g, ' ')

    // capitalize status
    const capitalizedString = formattedString
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
    return capitalizedString
  }
  return (
    <div className='TGPlusSubscriptionDetails tgplus-stations-subscription'>
      <div className='container'>
        <div className='container mb-5'>
          <BreadCrumb
            title={name ?? ''}
            parent={'Tradegrid Plus'}
            parentUrl={'tgplus-subscription'}
            showTitle={false}
          ></BreadCrumb>
          <h6 className='mt-3'>All {name} Subscriptions</h6>
          <div className='row mt-4'>
            {loading === true && order.length === 0 && (
              <tr>
                <td colSpan={8} className='text-center'>
                  <HorizontalLoader></HorizontalLoader>
                </td>
              </tr>
            )}
            {order.length > 0 &&
              order.map((data: tgplusModel, index: Key) => (
                <div className='col-xl-6' key={index}>
                  <div className='card border-0 mb-3'>
                    <div className='card-header bg-white py-3'>
                      <div className='row'>
                        <div className='col-3 col-md-2 subId'>TGP #{Number(index) + 1}</div>
                        <div className='col-6 col-md-4 subDate'>
                          <svg
                            width='5'
                            height='5'
                            viewBox='0 0 5 5'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className='me-3'
                          >
                            <path
                              d='M2.28 4.624C1.736 4.624 1.272 4.43733 0.888 4.064C0.514667 3.68 0.328 3.216 0.328 2.672C0.328 2.11733 0.514667 1.65333 0.888 1.28C1.272 0.896 1.736 0.704 2.28 0.704C2.83467 0.704 3.304 0.896 3.688 1.28C4.072 1.65333 4.264 2.11733 4.264 2.672C4.264 3.216 4.072 3.68 3.688 4.064C3.304 4.43733 2.83467 4.624 2.28 4.624Z'
                              fill='#808080'
                            />
                          </svg>
                          {TimeConverter(data.createdAt)}
                        </div>

                        <div className='col-4 col-md-6 text-md-end mt-2 mt-md-0'>
                          <span
                            className={
                              ['APPROVED', 'INSTALLATION_COMPLETED'].includes(data.status)
                                ? 'status-success'
                                : 'status-danger'
                            }
                          >
                            {data.status}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='capacity'>
                            {data.solarPowerCapacity ?? 0}kva Solar Capacity
                          </div>
                        </div>
                        <div className='col-md-6 text-lg-end'>
                          <div className='capacity-price'>
                            N{CurrencyFormatter(data.monthlySubscriptionMajor ?? 0)}/m
                          </div>
                        </div>
                        <div className='col-md-12 station-details  mt-3'>
                          {data.stationName} | {data.stationAddress}. {data.stationState}.
                        </div>
                        <div className='col-md-12 next-due-date  mt-3'>
                          Next Due Date {TimeConverter(data.nextSubscriptionPaymentDueDate)}
                        </div>
                      </div>
                    </div>
                    <div className='card-footer bg-white text-center py-3'>
                      <Link to={`/tgplus-subscription/${data.id}`}>View Details</Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TGPlusStationSubscription
